
html, body {
	background: #222;
	color: rgb(240, 240, 240);
	font-family: Monospace;
	height: 100%;
	margin: 0;
	overflow: hidden;
	padding: 0;
	width: 100%;
}

object {
	visibility: hidden;
	width: 0;
	height: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	border-style: none;
	border-width: 0;
	max-width: 0;
	max-height: 0;
}

.fps-counter {
	position: absolute;
	left: 10px;
	top: 10px;
	margin: 0;
}